import { Menu, MenuItem, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SetListClient, SetListViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import { UserContext } from '../../utils/UserStorage';
import WarningModal from '../elements/warning-modal/WarningModal';
import { Edit, Share, Archive, Reply, PushPin } from '@mui/icons-material';
import ShareModal from './ShareModal';
import { SetSnackbarErrorContext, SetSnackbarSuccessContext } from '../elements/snackbar/SnackbarContext';
import { colours } from '../../Theme';

interface Props {
  setList: SetListViewModel;
  setSetList: (setList: SetListViewModel) => void;
  anchorElUser: HTMLElement | null;
  setAnchorElUser: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  onEdit?: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const iconStyle = { marginRight: 5, fontSize: 20 };

const SetListCardMenu = (props: Props): JSX.Element => {
  const user = useContext(UserContext);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [amDeleting, setAmDeleting] = useState(true);
  const [shareOpen, setShareOpen] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  const handleCloseUserMenu = (): void => {
    props.setAnchorElUser(null);
  };

  return (
    <>
      <WarningModal
        open={warningModalOpen}
        setOpen={setWarningModalOpen}
        title={(amDeleting ? 'Archive' : 'Leave') + ' Set List'}
        description={
          'This action cannot be undone! Are you sure you want to ' +
          (amDeleting ? 'archive' : 'leave') +
          ' this set list?'
        }
        actionButton={amDeleting ? 'Archive' : 'Leave'}
        onAction={() => {
          if (amDeleting) {
            new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
              .deleteSetList(props.setList.id)
              .then(() => {
                setWarningModalOpen(false);
                props.setRefresh(x => !x);
              })
              .catch(() => setSnackbarError('Could not archive set list. Please try again later'));
          } else {
            new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
              .leaveSetList(props.setList.id)
              .then(() => {
                setWarningModalOpen(false);
                props.setRefresh(x => !x);
              })
              .catch(() => setSnackbarError('Could not leave set list. Please try again later'));
          }
        }}
      />
      <ShareModal setList={props.setList} open={shareOpen} setOpen={setShareOpen} setSetList={props.setSetList} />
      <Menu
        sx={{ mt: '45px' }}
        id='setListCardMenu'
        anchorEl={props.anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(props.anchorElUser)}
        onClose={handleCloseUserMenu}>
        {props.setList.userId === user.id && (
          <MenuItem
            key='Edit'
            onClick={() => {
              props.onEdit && props.onEdit();
              handleCloseUserMenu();
            }}>
            <Edit style={iconStyle} />
            <Typography>Edit</Typography>
          </MenuItem>
        )}
        {props.setList.userId === user.id && !props.setList.deleted && (
          <MenuItem
            key='Share'
            onClick={() => {
              setShareOpen(true);
              handleCloseUserMenu();
            }}>
            <Share style={iconStyle} />
            <Typography>Share</Typography>
          </MenuItem>
        )}

        {props.setList.userId === user.id && !props.setList.deleted && (
          <MenuItem
            key='Pin'
            onClick={() => {
              const sl = props.setList;
              sl.pinned = !sl.pinned;
              new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                .updateSetList(sl, [])
                .then(() => {
                  handleCloseUserMenu();
                  props.setRefresh(x => !x);
                })
                .catch(() => {
                  setSnackbarError('Could not pin set list. Please try again later');
                });
            }}>
            <PushPin style={iconStyle} />
            <Typography>{props.setList.pinned ? 'Unpin' : 'Pin'}</Typography>
          </MenuItem>
        )}
        {props.setList.userId === user.id &&
          (!props.setList.deleted ? (
            <MenuItem
              key='Delete'
              onClick={() => {
                setAmDeleting(true);
                setWarningModalOpen(true);
                handleCloseUserMenu();
              }}>
              <Archive style={{ ...iconStyle, color: colours.textOrange }} />
              <Typography sx={{ color: colours.textOrange }}>Archive</Typography>
            </MenuItem>
          ) : (
            <MenuItem
              key='Restore'
              onClick={() => {
                new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                  .restoreSetList(props.setList.id)
                  .then(() => {
                    setSnackbarSuccess('Set List Restored');
                    setWarningModalOpen(false);
                    props.setRefresh(x => !x);
                  })
                  .catch(() => setSnackbarError('Could not unarchive set list. Please try again later'));
                handleCloseUserMenu();
              }}>
              <Archive style={{ ...iconStyle, color: colours.textOrange }} />
              <Typography sx={{ color: colours.textOrange }}>Restore</Typography>
            </MenuItem>
          ))}
        {props.setList.userId !== user.id && !props.setList.deleted && (
          <MenuItem
            key='Leave'
            onClick={() => {
              setAmDeleting(false);
              setWarningModalOpen(true);
              handleCloseUserMenu();
            }}>
            <Reply style={{ ...iconStyle, color: 'red' }} />
            <Typography sx={{ color: 'red' }}>Leave Set List</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default SetListCardMenu;
